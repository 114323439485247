import * as React from "react"
import { Container, Row, Col } from 'reactstrap';
import { StaticImage } from "gatsby-plugin-image"

const IndexText = () =>{
  return (
    <Container className="index-text-block">
        <h1 className="index-heading">
            <span className="blue-color">
                Ohm Electronics 
            </span>
            &nbsp;— долгая жизнь электроники
        </h1>
        
      <Row>
          <Col>
          <p>
            Мы производим диагностику и ремонт промышленной и специализированной электроники различного оборудования (на уровне пайки компонентов) любой степени сложности. Ремонт промышленных компьютеров, печатных плат и модулей управления, контроллеров, преобразователей, источников питания, интерфейсных плат автоматизированных линий, станков, машин. С учетом рентабельности, отремонтируем электронику устаревшего, либо снятого с производства промышленного оборудования, включая сложные случаи, а также при отсутствии электрических принципиальных схем и пр. При необходимости, подберем аналогичные компоненты, с учетом взаимозаменяемости. В случае нерентабельности или невозможности ремонта, инженеры нашей компании могут разработать аналогичное устройство на современной элементной базе с оформлением документации по ГОСТ в соответствии с ЕСКД.
          </p>
          </Col>
          <div>
            <StaticImage src="../images/index-small.png" alt="Ohm Electronics — долгая жизнь электроники"/>
          </div>
      </Row>
    </Container>
  )
}

export default IndexText