import * as React from "react"
import { Container, Row, Col } from 'reactstrap';
import { StaticImage } from "gatsby-plugin-image"

const ServicesData = [
  {
    icon: <StaticImage src="../images/services-1.png" alt="Печатные платы"/>,
    title: "Печатные платы",
    description: "Компания Ohm Electronics осуществляет полный цикл штучного и мелкосерийного производства и монтажа ",
  }, 
   {
    icon: <StaticImage src="../images/services-2.png" alt="Печатные платы"/>,
    title: "Механическая обработка",
    description: "Высокоточные станки (Япония), позволяют выполнять фрезерные (3d+поворотная ось) работы, необходимые для изготовления и ремонта установочных  оборудования"
  },  
  {
    icon: <StaticImage src="../images/services-3.png" alt="Печатные платы"/>,
    title: "Инфографика",
    description: "Восстанавливаем, изготавливаем металлические шильды (в том числе с цветным изображением), панели приборов и пультов. управления"
  },
];

const IndexServices = () =>{
  return (
    <Container className="index-services">
      <Row>
        {ServicesData.map(service => (
          <Col key={service.title}>
            <div className="index-services-icon">
              {service.icon}
            </div>
            <div className="index-services-title">
              {service.title}
            </div>
            <p className="index-services-description">
              {service.description}
            </p>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default IndexServices