import * as React from "react"
import { Container, Row, Col } from 'reactstrap';
import { StaticImage } from "gatsby-plugin-image"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    variableWidth: true,
    slidesToScroll: 1
};

const IndexSlider = () =>{
  return (
    <Slider {...settings}>
        <div>
            <StaticImage src="../images/1.jpg" alt="О компании" layout="constrained" />
        </div>
        <div>
            <StaticImage src="../images/2.jpg" alt="О компании" layout="constrained"/>
        </div>
        <div>
            <StaticImage src="../images/3.jpg" alt="О компании" layout="constrained"/>
        </div>
        <div>
            <StaticImage src="../images/4.jpg" alt="О компании" layout="constrained"/>
        </div>
        <div>
            <StaticImage src="../images/5.jpg" alt="О компании" layout="constrained"/>
        </div>
        <div>
            <StaticImage src="../images/6.jpg" alt="О компании" layout="constrained"/>
        </div>
    </Slider>
  )
}

export default IndexSlider