import * as React from "react"
import Layout from "../components/Layout.js"
import IndexServices from "../components/IndexServices"
import IndexText from "../components/IndexText"
import IndexSlider from "../components/IndexSlider.js"
import MapBlock from "../components/MapBlock.js"



const IndexPage = () => {
  return (
      <Layout>
        <title>Главная страница</title>
        <IndexSlider/>
        <IndexServices/>
        <IndexText/>
        <MapBlock/>
      </Layout>
  )
}

export default IndexPage
